<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12" md="4"
      >
        <v-autocomplete
            v-model="itemModified.freeGood1MaterialBean"
            item-value="id"
            item-text="pname"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Mendapatkan Voucher"
            :hint="`${lookupFMaterial(itemModified.freeGood1MaterialBean).pcode}` "
        ></v-autocomplete>

      </v-col>

    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1"></v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="4" md="4"
      >
        <span>Minimal Potensi Voucher</span>
      </v-col>
      <v-col
          cols="12"
          sm="4" md="4"
      >
        <span>Maksimal Potensi Voucher</span>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="1" md="1"
      >
        <v-flex class="subtitle-1">1.</v-flex>
      </v-col>

      <v-col
          cols="12"
          sm="4" md="4"
      >
        <v-text-field
            v-model="itemModified.forFg1QtyOrValueLev1"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="4" md="4"
      >
        <v-text-field
            v-model="itemModified.forFg1QtyOrValueLev1"
            dense
            type="number"

            hide-details
        ></v-text-field>
      </v-col>


    </v-row>



  </div>
</template>

<script>
export default {
  name: "FPromotionRuleshWhatCustGetsDiscount",
  computed:{
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },

    itemsFMaterial(){
      return this.$store.state.data.listFMaterial.filter(x => x.statusActive===true)
    },
  },
  methods:{
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

  }
}
</script>

<style scoped>

</style>