<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.discMinQtyOrValue"
            label="Min Pengambilan"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.discMaxQtyOrValue"
            label="MAX Pengambilan"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-switch
            v-model="itemModified.discCashOnly"
            :label="itemModified.discCashOnly?'Tunai Saja':'Kredit & Tunai'"
            x-small
            dense
            hide-details
        ></v-switch>

      </v-col>

    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">1.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscQtyOrValueLev1"
            dense
            label="Nominal/Qty"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.disc1GetLev1_Value"
            dense
            label="Disc Rupiah per KRT"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1GetLev1"
            dense
            label="Disc-1 (%)"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">2.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscQtyOrValueLev2"
            dense
            label="Nominal/Qty"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.disc1GetLev2_Value"
            dense
            label="Disc Rupiah per KRT"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1GetLev2"
            dense
            label="Disc-1 (%)"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">3.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscQtyOrValueLev3"
            dense
            label="Nominal/Qty"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.forDiscQtyOrValueLev3"
            dense
            label="Disc Rupiah per KRT"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1GetLev3"
            dense
            label="Disc-1 (%)"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">4.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscQtyOrValueLev4"
            dense
            label="Nominal/Qty"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.disc1GetLev4_Value"
            dense
            label="Disc Rupiah per KRT"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc2GetLev4"
            dense
            label="Disc-1 (%)"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">5.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscQtyOrValueLev5"
            dense
            label="Nominal/Qty"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.disc1GetLev5_Value"
            dense
            label="Disc Rupiah per KRT"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1GetLev5"
            dense
            label="Disc-1 (%)"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>


  </div>
</template>

<script>
export default {
  name: "FPromotionRuleshWhatCustGetsDiscount",
  computed:{
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },

  }
}
</script>

<style scoped>

</style>