<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>

    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fpromotionRuleshsFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
        align="start"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>

      <template v-slot:[`item.avatar`]="{ item}">
        <v-img
            :lazy-src="lookupImageUrlLazy(item)"
            :src="lookupImageUrl(item)"
            alt="avatar"
            width="80px"
            height="80px"
            class="ma-2 rounded"
        >
        </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-2">
          {{item.description}}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.kode1">
          *{{item.kode1}}
        </div>
        <div v-if="item.npwp" class="caption small">
          NPWP <span class="font-italic">{{item.npwp}}</span>
        </div>
      </template>

      <template v-slot:[`item.mekanisme`]="{ item }">
        <div class="fill-height">
          <div>{{computedDateFormattedDatefns(item.validPeriodDateFrom)}} ~ {{computedDateFormattedDatefns(item.validPeriodDateTo)}}</div>
          <div>{{lookupCalcMethod(item.calcMethod)}}</div>
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div  class="text-caption small">
            {{ lookupFDivision(item.fdivisionBean) }}
          </div>
          <div>
            <v-chip
                :color="getColorStatusActive(item.statusActive)"
                dark
                x-small
            >
              {{ item.statusActive==true?'Aktif': '-' }}
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteDialog>

    <FPromotionRuleshDialog
        :formMode.sync="formMode"
        :itemsFDivision="itemsFDivision"
        ref="refFormDialog"
        @eventFromFormDialogNew="saveDataNew"
        @eventFromFormDialogEdit="saveDataEdit"
    ></FPromotionRuleshDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FPromotionRuleshService from '../../../services/apiservices/f-promotion-rulesh-service';
import FDivisionService from "../../../services/apiservices/f-division-service";
import FileService from "../../../services/apiservices/file-service"

import DeleteDialog from "../../../components/utils/DeleteConfirmDialog";
import FPromotionRuleshDialog from "./FPromotionRuleshDialog";
import FormMode from "../../../models/form-mode";
import FPromotionRulesh from '../../../models/f-promotion-rulesh'

import {format, parseISO} from "date-fns";
import FCustomer from "@/models/f-customer";

export default {
  components: {
    FPromotionRuleshDialog, DeleteDialog
  },
  data () {
    return {
      title: 'PROMOTIONS',

      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'avatar',
          width: '20%'
        },
        { text: 'Promotions', value: 'description', width:'25%' },
        { text: 'Mekanisme', value: 'mekanisme', width: '30%' },
        { text: 'Branch', value: 'fdivisionBean', width: '15%' },
        { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      fpromotionRuleshs:[],
      itemsFDivision: [],
      itemsCalcMethod: [
        {id: 0, description: '0. Dikalkulasi Setelah Save'},
        {id: 1, description: '1. Dikalkulasi Saat Mode Edit dan Setelah Save'},
        {id: 2, description: '2. Dikalkulasi Saat Mode Edit Saja'},
      ],

    }

  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFPromotionRulesh()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFPromotionRulesh()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    fpromotionRuleshsFiltered(){
      return this.fpromotionRuleshs
    }
  },
  methods: {

    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFPromotionRulesh()
      }
    },
    fetchParent(){

      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

      this.itemsFCustomer = [ new FCustomer(0, '0', 'All Customer')]

      this.$store.dispatch('data/loadFArea')
      this.$store.dispatch('data/loadFRegion')

      this.$store.dispatch('data/loadFCustomerGroup')
      this.$store.dispatch('data/loadFDistributionChannel')


      this.$store.dispatch('data/loadFMaterial')
      this.$store.dispatch('data/loadFMaterialGroup3')
      this.$store.dispatch('data/loadFMaterialGroup2')
      this.$store.dispatch('data/loadFMaterialGroup1')
      this.$store.dispatch('data/loadFMaterialSalesBrand')
      this.$store.dispatch('data/loadFMaterialGroup3')

      this.$store.dispatch('data/loadFVendor')

    },

    fetchFPromotionRulesh() {
      FPromotionRuleshService.getAllFPromotionRuleshContaining(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {
            // console.log(JSON.stringify(response.data.items))

            const { items, totalPages} = response.data
            this.fpromotionRuleshs = items
            this.totalPaginationPages = totalPages

            console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.status===401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )
    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {
      this.itemSelectedIndex =-1
      const newItem = Object.assign({}, '')
      this.formMode = FormMode.NEW_FORM

      this.$store.dispatch('aktifitaspromo/updateItemModified', newItem)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex)
    },
    saveDataNew(itemFromRest){
      this.itemModified = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat:
       * Karena menggunakan Pagination
       * Maka tidak menggunakan Push Array Biasa
       */
      this.fetchFPromotionRulesh()
    },

    showDialogEdit (value) {
      this.itemSelectedIndex = this.fpromotionRuleshsFiltered.indexOf(value)
      const editedItem = Object.assign({}, value)
      this.formMode = FormMode.EDIT_FORM

      this.$store.dispatch('aktifitaspromo/updateItemModified', editedItem)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex)
    },
    saveDataEdit(itemFromRest){
        this.itemModified = itemFromRest
        try {
          Object.assign(this.fpromotionRuleshs[this.itemSelectedIndex], this.itemModified)
        }catch (e){
          this.fetchFPromotionRulesh()
          e.return
        }
      this.closeDialog()

    },

    deleteDialogShow (item) {
      this.itemSelectedIndex = this.fpromotionRuleshsFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(){

      const deletedItem = this.fpromotionRuleshs[this.itemSelectedIndex]
      FPromotionRuleshService.deleteFPromotionRulesh(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fpromotionRuleshs.splice(this.itemSelectedIndex, 1)
          this.closeDeleteDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(itemsFromRest){
      // console.log(okeMessage)
      if (itemsFromRest.length >-1){
        let itemIds = []
        for (let i=0; i<itemsFromRest.length; i++){
            itemIds.push(itemsFromRest[i].id)
        }

        FPromotionRuleshService.deleteAllFPromotionRulesh(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < itemsFromRest.length; i++) {
                    if (! response.data.includes(itemsFromRest[i].id)  || response.data.length === 0) {
                      const index = this.fpromotionRuleshsFiltered.indexOf(itemsFromRest[i])
                      this.fpromotionRuleshs.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                }
                this.closeDeleteDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FPromotionRuleshService.deleteAllFPromotionRuleshs()
      }
    },

    closeDialog () {
      this.formMode = ''
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FPromotionRulesh())
        this.itemSelectedIndex = -1
      })
    },
    closeDeleteDialog () {
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FPromotionRulesh())
        this.itemSelectedIndex = -1
      })
    },
    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupCalcMethod (calcMethodId) {
      const str = this.itemsCalcMethod.filter(x => x.id===calcMethodId)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },


    onImgError: function(event) {
      /**
       * masih belum fungsi
       */
      console.log(event)
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchFPromotionRulesh()
      this.fetchParent()

    }

  }

}
</script>

<style scoped>

</style>
