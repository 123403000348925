<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12" md="4"
      >
        <v-text-field
            v-if="false"
            v-model="itemModified.discMinQtyOrValue"
            label="Min Pengambilan"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-if="false"

            v-model="itemModified.discMaxQtyOrValue"
            label="MAX Pengambilan"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-switch
            v-model="itemModified.discNotaCashOnly"
            :label="itemModified.discNotaCashOnly?'Tunai Saja':'Kredit & Tunai'"
            x-small
            dense
            hide-details
        ></v-switch>

      </v-col>

    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">1.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscNotaValueLev1"
            dense
            label="Sub Total Item Barang"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1NotaGetLev1"
            dense
            label="Disc Nota-1 (%)"
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.cashBackNotaGetLev1"
            dense
            label="Cash Back"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">2.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscNotaValueLev2"
            dense
            label="Sub Total Item Barang"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1NotaGetLev2"
            dense
            label="Disc Nota-1 (%)"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.cashBackNotaGetLev2"
            dense
            label="Cash Back"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">3.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscNotaValueLev3"
            dense
            label="Sub Total Item Barang"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1NotaGetLev3"
            dense
            label="Disc Nota-1 (%)"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.cashBackNotaGetLev3"
            dense
            label="Cash Back"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">4.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscNotaValueLev4"
            dense
            label="Sub Total Item Barang"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1NotaGetLev4"
            dense
            label="Disc Nota-1 (%)"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.cashBackNotaGetLev4"
            dense
            label="Cash Back"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">5.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forDiscNotaValueLev5"
            dense
            label="Sub Total Item Barang"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.disc1NotaGetLev5"
            dense
            label="Disc Nota-1 (%)"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="3"
      >
        <v-text-field
            v-model="itemModified.cashBackNotaGetLev5"
            dense
            label="Cash Back"
            type="number"

            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

  </div>
</template>

<script>
export default {
  name: "FPromotionRuleshWhatCustGetsDiscount",
  computed:{
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },

  }
}
</script>

<style scoped>

</style>