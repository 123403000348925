<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-form v-model="valid"></v-form>
      <div v-if="isItemModified"></div>
      <div v-if="formMode"></div>
      <v-card>
        <v-form v-model="valid" ref="form">

          <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
            dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ this.itemModified.description }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up mt-10"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>

            <v-container>
              <v-tabs>
                <v-tab>General Mechanism</v-tab>
                <v-tab>Valid Customer</v-tab>
                <v-tab>What Customer Buys</v-tab>
                <v-tab>What Customer Gets(DC/FG)</v-tab>
                <v-tab>Payment</v-tab>
                <v-tab>Log</v-tab>
                <v-tab-item>
                  <FPromotionRuleshGeneral
                      :itemsFDivision="itemsFDivision"
                  ></FPromotionRuleshGeneral>
                </v-tab-item>
                <v-tab-item>
                  <FPromotionRulesdValidCusts>
                  </FPromotionRulesdValidCusts>
                </v-tab-item>
                <v-tab-item>
                  <FPromotionRulesdValidProducts>
                  </FPromotionRulesdValidProducts>
                </v-tab-item>
                <v-tab-item>
                  <FPromotionRuleshWhatCustomerGets>
                  </FPromotionRuleshWhatCustomerGets>
                </v-tab-item>

              </v-tabs>

            </v-container>

          </v-card-text>

          <v-card-actions class="mr-8 mb-2">
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

    </v-dialog>

  </div>

</template>

<script>
import FPromotionRuleshService from '../../../services/apiservices/f-promotion-rulesh-service';

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import { format, parseISO } from 'date-fns'
import FPromotionRuleshGeneral from "./FPromotionRuleshGeneral"
import FPromotionRulesdValidCusts from "./FPromotionRulesdValidCusts";
import FPromotionRulesdValidProducts from "@/components/promotions/setting-promosi/FPromotionRulesdValidProducts";
import FPromotionRuleshWhatCustomerGets from "@/components/promotions/setting-promosi/FPromotionRuleshWhatCustGets";

export default {
  components: {
    FPromotionRuleshWhatCustomerGets,
    FPromotionRulesdValidProducts,
    FPromotionRulesdValidCusts, FPromotionRuleshGeneral, CloseConfirmDialog
  },
  props:{
    formMode: String,
    itemsFDivision:[],
  },

  data() {
    return {
      title: 'PROMOTIONS',

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },
      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      menuDate2: false,
      menuDate3: false,

      selectedItemIndex: -1,
      itemDefault: '',
      // itemModified: new FPromotionRulesh(0, '', ''),
      selectFDivision: '',
      itemsFile:[],

      fwarehouseExpedisies: [],
      itemsValidCusts: [],
      itemsValidProducts: [],

      valid: false,

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },

    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },

  },

  watch: {
  },

  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    showDialog(selectedIndex){

      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(this.itemModified)
      }else {
        this.selectedIndex = -1
      }
    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(andCloseDialog){
      // console.log(andCloseDialog)
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }

      this.itemModified.modifiedBy = this.$store.state.auth.user.username;
      this.itemModified.modified = new Date();

      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {

          // console.log(`cek sebelum update >> ${this.itemModified.description}`)
          // console.log(`cek sebelum update >> ${JSON.stringify(this.itemModified)}`)

          FPromotionRuleshService.updateFPromotionRulesh(this.itemModified).then(
              () => {
                // console.log(response.data)
                if (andCloseDialog){
                  this.$emit('eventFromFormDialogEdit', this.itemModified)
                }
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {

          if (this.itemModified.fdivisionBean >0 && this.itemModified.kode1 !==undefined &&
              this.itemModified.description !==undefined){

            FPromotionRuleshService.createFPromotionRulesh(this.itemModified).then(
                response =>{
                  if (andCloseDialog){
                    this.$emit('eventFromFormDialogNew', response.data)
                  }
                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )
          }
        }//endif
      }//endif validate

    },

    saveCreateOnly(){
      FPromotionRuleshService.createFPromotionRulesh(this.itemModified).then(
          response =>{
              /**
               * dipaksa Save dan Update Dahulu
               */
              // this.initializeEditMode(response.data)
            this.$emit('update:formMode', FormMode.EDIT_FORM)
            this.itemModified.id = response.data.id
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){

      this.formDialogOptions.errorMessage = ''
      FPromotionRuleshService.getFPromotionRuleshById(item.id).then(
          response =>{
            // console.log(JSON.stringify(response.data))

            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data

            this.fetchValidCusts()
            this.fetchValidProducts()

          },
          error =>{
            console.log(error)
          }
      )


    },
    fetchValidCusts(){
      this.$store.dispatch('aktifitaspromo/loadItemsValidCusts', this.itemModified.id)
      // FPromotionRulesdValidCustsService.getAllFPromotionRulesdValidCustsByParent(this.itemModified.id).then(
      //     response =>{
      //
      //       // console.log(JSON.stringify(response.data))
      //
      //       this.itemsValidCusts = response.data
      //     },
      //     error =>{
      //       console.log(error)
      //     }
      // )

    },
    fetchValidProducts(){
      this.$store.dispatch('aktifitaspromo/loadItemsValidProducts', this.itemModified.id)

      // FPromotionRulesdValidProductsService.getAllFPromotionRulesdValidProductsByParent(this.itemModified.id).then(
      //     response =>{
      //       this.itemsValidProducts = response.data
      //     },
      //     error =>{
      //       console.log(error)
      //     }
      // )

    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  }

}
</script>

<style scoped>
</style>