<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6" md="6"
      >
        <v-autocomplete
            v-model="itemModified.freeGood1MaterialBean"
            :items="itemsFMaterial"
            item-value="id"
            item-text="pname"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Mendapatkan Free Good-1"
            :hint="`${lookupFMaterial(itemModified.freeGood1MaterialBean).pcode}` "
        ></v-autocomplete>

      </v-col>
      <v-col
          cols="12"
          sm="3" md="3"
      >
        <v-text-field
            v-model="itemModified.fg1MinQtyOrValue"
            label="Min Pengambilan"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-switch
            v-model="itemModified.fg1Kelipatan"
            :label="itemModified.fg1Kelipatan?'Berlaku Kelipatan':'Tidak Kelipatan'"
            x-small
            dense
            hide-details
        ></v-switch>

      </v-col>

    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1"></v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <span>Kelipatan Nominal/Qty</span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <span>Get Free Qty In PCS/UOM4</span>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">1.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forFg1QtyOrValueLev1"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.fg1QtyGetLev1"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">2.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forFg1QtyOrValueLev2"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.fg1QtyGetLev2"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">3.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forFg1QtyOrValueLev3"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.fg1QtyGetLev3"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">4.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forFg1QtyOrValueLev4"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.fg1QtyGetLev4"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row class="mt-2">
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <v-flex class="subtitle-1">5.</v-flex>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="4"
      >
        <v-text-field
            v-model="itemModified.forFg1QtyOrValueLev5"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="1"
      >
        <span class="subtitle-1"> >= </span>
      </v-col>
      <v-col
          cols="12"
          sm="12" md="2"
      >
        <v-text-field
            v-model="itemModified.fg1QtyGetLev5"
            dense
            type="number"
            
            hide-details
        ></v-text-field>
      </v-col>

    </v-row>


  </div>
</template>

<script>
export default {
  name: "FPromotionRuleshWhatCustGetsDiscount",
  computed:{
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },

    itemsFMaterial(){
      return this.$store.state.data.listFMaterial.filter(x => x.statusActive===true)
    },
  },
  methods:{
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

  }
}
</script>

<style scoped>

</style>