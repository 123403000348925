<template>

  <v-card class="mt-2 ml-2 mr-2">
    <FPromotionRuleshTable ref="refTabPromotionRuleshClick"/>
  </v-card>

</template>

<script>
import FPromotionRuleshTable from "@/components/promotions/setting-promosi/FPromotionRuleshTable";

export default {
  components: { FPromotionRuleshTable },
  data() {
    return {
      firstTabTitle: 'SETTING PROMOTIONS',
      firstTabIcon: 'mdi-gift'
    }
  },
  methods: {
    tabMaterialClick(){
      try {
        this.$refs.refTabPromotionRuleshClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },

  }
}
</script>

<style scoped>

</style>