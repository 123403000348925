var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ma-6"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsValidProducts},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ml-4 mr-4",attrs:{"align":"center"}},[_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","color":"green","x-small":""},on:{"click":_vm.showDialogNew}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.validMaterialBean",fn:function(ref){
var item = ref.item;
return [(item.validMaterialBean===0)?_c('div',[_c('span',[_vm._v("All Product")])]):_c('div',[_c('span',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFMaterial(item.validMaterialBean).pcode)+" ")]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.lookupFMaterial(item.validMaterialBean).pname)+" ")])])]}},{key:"item.validMaterialGroup3Bean",fn:function(ref){
var item = ref.item;
return [(item.validMaterialGroup3Bean===0)?_c('div',[_c('span',[_vm._v("All Material Group3")])]):_c('div',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFMaterialGroup3(item.validMaterialGroup3Bean).description)+" ")])]}},{key:"item.validMaterialGroup2Bean",fn:function(ref){
var item = ref.item;
return [(item.validMaterialGroup2Bean===0)?_c('div',[_c('span',[_vm._v("All Material Group2")])]):_c('div',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFMaterialGroup2(item.validMaterialGroup2Bean).description)+" ")])]}},{key:"item.validMaterialSalesBrandBean",fn:function(ref){
var item = ref.item;
return [(item.validMaterialSalesBrandBean===0)?_c('div',[_c('span',[_vm._v("All Sales Brand")])]):_c('div',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFMaterialSalesBrand(item.validMaterialSalesBrandBean).description)+" ")])]}},{key:"item.validVendorBean",fn:function(ref){
var item = ref.item;
return [(item.validVendorBean===0)?_c('div',[_c('span',[_vm._v("All Vendor")])]):_c('div',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFVendor(item.validVendorBean).vname)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showDialogEdit(item)}}},[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteDialogShow(item)}}},[_c('v-icon',{attrs:{"small":"","color":"red accent-4"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1),_c('FPromotionRulesdValidProductsDialog',{ref:"refFormDialog",attrs:{"formMode":_vm.formMode,"title":_vm.title},on:{"update:formMode":function($event){_vm.formMode=$event},"update:form-mode":function($event){_vm.formMode=$event},"eventFromFormDialogNew":_vm.saveDataNew,"eventFromFormDialogEdit":_vm.saveDataEdit}}),_c('DeleteDialog',{ref:"refDeleteConfirmDialog",on:{"eventFromDeleteConfirmDialog1":_vm.deleteItemConfirmedSingleSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }