<template>
  <v-container>
    <div class="ma-6">

      <v-data-table
        :headers="headers"
        :items="itemsValidProducts"
      >
        <template v-slot:top>
          <v-row align="center" class="ml-4 mr-4">
            <v-spacer></v-spacer>
            <v-btn
                fab
                dark
                color="green"
                x-small
                @click="showDialogNew"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </template>

        <template v-slot:[`item.number`]="{ index }">
          {{index+1}}
        </template>

        <template v-slot:[`item.validMaterialBean`]="{ item }">
          <div v-if="item.validMaterialBean===0">
            <span>All Product</span>
          </div>
          <div v-else>
            <span class="blue--text">
              {{ lookupFMaterial(item.validMaterialBean).pcode  }}
            </span>
            <span class="grey--text">
              {{ lookupFMaterial(item.validMaterialBean).pname  }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.validMaterialGroup3Bean`]="{ item }">
          <div v-if="item.validMaterialGroup3Bean===0">
            <span>All Material Group3</span>
          </div>
          <div v-else class="blue--text">
            {{ lookupFMaterialGroup3(item.validMaterialGroup3Bean).description }}
          </div>
        </template>
        <template v-slot:[`item.validMaterialGroup2Bean`]="{ item }">
          <div v-if="item.validMaterialGroup2Bean===0">
            <span>All Material Group2</span>
          </div>
          <div v-else class="blue--text">
            {{ lookupFMaterialGroup2(item.validMaterialGroup2Bean).description }}
          </div>
        </template>
        <template v-slot:[`item.validMaterialSalesBrandBean`]="{ item }">
          <div v-if="item.validMaterialSalesBrandBean===0">
            <span>All Sales Brand</span>
          </div>
          <div v-else class="blue--text">
            {{ lookupFMaterialSalesBrand(item.validMaterialSalesBrandBean).description }}
          </div>
        </template>
        <template v-slot:[`item.validVendorBean`]="{ item }">
          <div v-if="item.validVendorBean===0">
            <span>All Vendor</span>
          </div>
          <div v-else class="blue--text">
            {{ lookupFVendor(item.validVendorBean).vname }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
              @click="showDialogEdit(item)"
              icon
          >
            <v-icon
                small
                color="warning"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
              @click="deleteDialogShow(item)"
              icon
          >
            <v-icon
                small
                color="red accent-4"
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

    </v-data-table>

    </div>

    <FPromotionRulesdValidProductsDialog
        :formMode.sync="formMode"
        :title="title"
        ref="refFormDialog"

        @eventFromFormDialogNew="saveDataNew"
        @eventFromFormDialogEdit="saveDataEdit"
    ></FPromotionRulesdValidProductsDialog>

    <DeleteDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
    ></DeleteDialog>
    
  </v-container>
</template>

<script>

import FPromotionRulesdValidProductsDialog from "./FPromotionRulesdValidProductsDialog";
import FormMode from "../../../models/form-mode";
import FPromotionRulesdValidProductsService from "@/services/apiservices/f-promotion-rulesd-valid-products-service";
import DeleteDialog from "../../../components/utils/DeleteConfirmDialog";

export default {
  components: {
    FPromotionRulesdValidProductsDialog,
    DeleteDialog
  },
  props:{
  },
  data(){
    return{
      title: '',

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Material/Product', value: 'validMaterialBean', width:'15%' },
        { text: 'Grup Lev3', value: 'validMaterialGroup3Bean', width:'15%' },
        { text: 'Grup Lev2', value: 'validMaterialGroup2Bean', width:'15%' },
        { text: 'Brand', value: 'validMaterialSalesBrandBean', width:'15%' },
        { text: 'Vendor/Suplier', value: 'validVendorBean', width:'15%' },
        { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
    }
  },
  watch:{
  },
  computed:{
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },
    itemValidProduct: {
      get () {
        return this.$store.state.aktifitaspromo.itemValidProduct
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemValidProduct', value)
      }
    },
    itemsValidProducts(){
      return this.$store.state.aktifitaspromo.itemsValidProducts
    },


    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },
    itemsFMaterialGroup3(){
      return this.$store.state.data.listFMaterialGroup3
    },
    itemsFMaterialGroup2(){
      return this.$store.state.data.listFMaterialGroup2
    },
    itemsFMaterialSalesBrand(){
      return this.$store.state.data.listFMaterialSalesBrand
    },
    itemsFVendor(){
      return this.$store.state.data.listFVendor
    }

  },
  methods: {

    deleteDialogShow (value) {
      this.itemSelectedIndex = this.itemsValidProducts.indexOf(value)
      this.itemValidProduct = Object.assign({}, value)
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, this.itemValidProduct.id )
    },
    deleteItemConfirmedSingleSelect(){
      const deletedItem = this.itemsValidProducts[this.itemSelectedIndex]
      FPromotionRulesdValidProductsService.deleteFPromotionRulesdValidProducts(deletedItem.id).then(
          () => {
            // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
            this.$store.dispatch('aktifitaspromo/loadItemsValidProducts',  this.itemModified.id )
            this.closeDeleteDialog()
          },
          error => {
            console.log(error)
            this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
            this.snackbar = true
            this.$refs.refDeleteConfirmDialog.setDialogState(false)
          }
      )
    },
    showDialogNew() {
      this.itemSelectedIndex =-1
      const newItem = Object.assign({}, '')
      this.formMode = FormMode.NEW_FORM

      this.$store.dispatch('aktifitaspromo/updateItemValidProduct', newItem)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex)
    },
    saveDataNew(itemFromRest){
      this.itemValidProduct = itemFromRest
      this.closeDialog()
      // this.$store.dispatch('aktifitaspromo/loadItemsValidProducts', this.itemModified.id ) //Jika menggunakan Pagination
      this.itemsValidProducts.push(this.itemValidProduct)

    },

    showDialogEdit (value) {
      this.itemSelectedIndex = this.itemsValidProducts.indexOf(value)
      const editedItem = Object.assign({}, value)
      this.formMode = FormMode.EDIT_FORM

      this.$store.dispatch('aktifitaspromo/updateItemValidProduct', editedItem)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex)

    },
    saveDataEdit(itemFromRest){
      this.itemValidProduct = itemFromRest
      try {
        // this.$store.dispatch('aktifitaspromo/loadItemsValidProducts', this.itemModified.id )
        Object.assign(this.itemsValidProducts[this.itemSelectedIndex], this.itemValidProduct)
      }catch (e){
        e.return
      }
      this.closeDialog()
    },
    closeDialog () {
      this.formMode = ''
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemValidProduct = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })

    },
    closeDeleteDialog () {
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemValidProduct = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })
    },

    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFMaterialGroup3 (fmaterialGroup3Bean) {
      const str = this.itemsFMaterialGroup3.filter(x => x.id===fmaterialGroup3Bean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFMaterialGroup2 (fmaterialGroup2Bean) {
      const str = this.itemsFMaterialGroup2.filter(x => x.id===fmaterialGroup2Bean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFMaterialSalesBrand(fmaterialSalesBrandBean) {
      const str = this.itemsFMaterialSalesBrand.filter(x => x.id===fmaterialSalesBrandBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFVendor(fvendorBean) {
      const str = this.itemsFVendor.filter(x => x.id===fvendorBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },


  }

}
</script>

<style scoped>

</style>
