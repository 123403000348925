<template>
  <v-container>
    <div class="ma-6">

      <v-data-table
        :headers="headers"
        :items="itemsValidCusts"
      >
        <template v-slot:top>
          <v-row align="center" class="ml-4 mr-4">
            <v-spacer></v-spacer>
            <v-btn
                fab
                dark
                color="green"
                x-small
                @click="showDialogNew"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </template>

        <template v-slot:[`item.number`]="{ index }">
          {{index+1}}
        </template>

        <template v-slot:[`item.validCustomerBean`]="{ item }">
          <div v-if="item.validCustomerBean===0">
            <span>All Customer</span>
          </div>
          <div v-else>
            {{ item.validCustomerBean }}
          </div>
        </template>
        <template v-slot:[`item.validAreaBean`]="{ item }">
          <div v-if="item.validAreaBean===0">
            <span>All Area</span>
          </div>
          <div v-else class="blue--text">
            {{ lookupFArea(item.validAreaBean).description }}
          </div>
        </template>
        <template v-slot:[`item.validRegionBean`]="{ item }">
          <div v-if="item.validRegionBean===0">
            <span>All Region/Propinsi</span>
          </div>
          <div v-else class="blue--text">
            {{ lookupFRegion(item.validRegionBean).description }}
          </div>
        </template>
        <template v-slot:[`item.validCustomerGroupBean`]="{ item }">
          <div v-if="item.validCustomerGroupBean===0">
            <span>All Jenis Cust</span>
          </div>
          <div v-else class="blue--text">
            {{ lookupFCustomerGroup(item.validCustomerGroupBean).description }}
          </div>
        </template>
        <template v-slot:[`item.validDistributionChannelBean`]="{ item }">
          <div v-if="item.validDistributionChannelBean===0">
            <span>All Channel</span>
          </div>
          <div v-else class="blue--text">
            {{ lookupFDistributionChannel(item.validDistributionChannelBean).description }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
              @click="showDialogEdit(item)"
              icon
          >
            <v-icon
                small
                color="warning"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
              @click="deleteDialogShow(item)"
              icon
          >
            <v-icon
                small
                color="red accent-4"
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

    </v-data-table>

    </div>

    <FPromotionRulesdValidCustsDialog
        :formMode.sync="formMode"
        :title="title"
        ref="refFormDialog"

        @eventFromFormDialogNew="saveDataNew"
        @eventFromFormDialogEdit="saveDataEdit"
    ></FPromotionRulesdValidCustsDialog>

    <DeleteDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
    ></DeleteDialog>
  </v-container>
</template>

<script>

import FPromotionRulesdValidCustsDialog from "./FPromotionRulesdValidCustsDialog";
import FormMode from "../../../models/form-mode";
import FPromotionRulesdValidCustsService from "@/services/apiservices/f-promotion-rulesd-valid-custs-service";
import DeleteDialog from "../../../components/utils/DeleteConfirmDialog";

export default {
  components: {
    FPromotionRulesdValidCustsDialog,
    DeleteDialog
  },
  props:{
  },
  data(){
    return{
      title: '',

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Customer', value: 'validCustomerBean', width:'15%' },
        { text: 'Area/Kab', value: 'validAreaBean', width:'13%' },
        { text: 'Region/Propinsi', value: 'validRegionBean', width:'13%' },
        { text: 'Customer group', value: 'validCustomerGroupBean', width:'13%' },
        { text: 'Dist Channel', value: 'validDistributionChannelBean', width:'13%' },
        { text: 'Promotion Flag', value: 'validPromotionFlagBean', width:'13%' },
        { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
    }
  },
  watch:{
  },
  computed:{
    itemsValidCusts(){
      return this.$store.state.aktifitaspromo.itemsValidCusts
    },
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },
    itemValidCust: {
      get () {
        return this.$store.state.aktifitaspromo.itemValidCust
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemValidCust', value)
      }
    },

    itemsFCustomer(){
      // return this.$store.state.data.listFCustomer
      return []
    },
    itemsFArea(){
      return this.$store.state.data.listFArea
    },
    itemsFRegion(){
      return this.$store.state.data.listFRegion
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    },
    itemsFDistributionChannel(){
      return this.$store.state.data.listFDistributionChannel
    }
  },
  methods: {

    deleteDialogShow (value) {
      this.itemSelectedIndex = this.itemsValidCusts.indexOf(value)
      this.itemValidCust = Object.assign({}, value)
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, this.itemValidCust.id )
    },
    deleteItemConfirmedSingleSelect(){
      const deletedItem = this.itemsValidCusts[this.itemSelectedIndex]
      FPromotionRulesdValidCustsService.deleteFPromotionRulesdValidCusts(deletedItem.id).then(
          () => {
            // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
            this.$store.dispatch('aktifitaspromo/loadItemsValidCusts',  this.itemModified.id )
            this.closeDeleteDialog()
          },
          error => {
            console.log(error)
            this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
            this.snackbar = true
            this.$refs.refDeleteConfirmDialog.setDialogState(false)
          }
      )
    },
    showDialogNew() {
      this.itemSelectedIndex =-1
      const newItem = Object.assign({}, '')
      this.formMode = FormMode.NEW_FORM

      this.$store.dispatch('aktifitaspromo/updateItemValidCust', newItem)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex)
    },
    saveDataNew(itemFromRest){
      this.itemValidCust = itemFromRest
      this.closeDialog()
      // this.$store.dispatch('aktifitaspromo/loadItemsValidCusts', this.itemModified.id ) //Jika menggunakan Pagination
      this.itemsValidCusts.push(this.itemValidCust)

    },

    showDialogEdit (value) {
      this.itemSelectedIndex = this.itemsValidCusts.indexOf(value)
      const editedItem = Object.assign({}, value)
      this.formMode = FormMode.EDIT_FORM

      this.$store.dispatch('aktifitaspromo/updateItemValidCust', editedItem)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex)

    },
    saveDataEdit(itemFromRest){
      this.itemValidCust = itemFromRest
      try {
        // this.$store.dispatch('aktifitaspromo/loadItemsValidCusts', this.itemModified.id )
        Object.assign(this.itemsValidCusts[this.itemSelectedIndex], this.itemValidCust)
      }catch (e){
        e.return
      }
      this.closeDialog()
    },
    closeDialog () {
      this.formMode = ''
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemValidCust = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })

    },
    closeDeleteDialog () {
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemValidCust = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })
    },
    lookupFArea (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFRegion(fregionBean) {
      const str = this.itemsFRegion.filter(x => x.id===fregionBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFCustomerGroup(fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id===fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFDistributionChannel(fdistributionChannelBean) {
      const str = this.itemsFDistributionChannel.filter(x => x.id===fdistributionChannelBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },


  }

}
</script>

<style scoped>

</style>
