<template>
  <v-container>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-black">
        DISCOUNT ITEM
      </v-card-subtitle>
      <v-card-text>
        <FPromotionRuleshWhatCustGetsDiscItem></FPromotionRuleshWhatCustGetsDiscItem>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-black">
        FREE GOOD-1
      </v-card-subtitle>
      <v-card-text>
        <FPromotionRuleshWhatCustGetsFg1></FPromotionRuleshWhatCustGetsFg1>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-black red--text">
        DISC NOTA
      </v-card-subtitle>
      <v-card-text>
        <FPromotionRuleshWhatCustGetsDiscNota></FPromotionRuleshWhatCustGetsDiscNota>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-black red--text">
        MP VOUCHER
      </v-card-subtitle>
      <v-card-text>
        <FPromotionRuleshWhatCustGetsVoucher/>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-black red--text">
        COINS
      </v-card-subtitle>
      <v-card-text>
      </v-card-text>
    </v-card>


  </v-container>
</template>

<script>
import FPromotionRuleshWhatCustGetsDiscItem from "@/components/promotions/setting-promosi/FPromotionRuleshWhatCustGetsDiscItem";
import FPromotionRuleshWhatCustGetsFg1 from "@/components/promotions/setting-promosi/FPromotionRuleshWhatCustGetsFg1";
import FPromotionRuleshWhatCustGetsDiscNota from "@/components/promotions/setting-promosi/FPromotionRuleshWhatCustGetsDiscNota";
import FPromotionRuleshWhatCustGetsVoucher from "@/components/promotions/setting-promosi/FPromotionRuleshWhatCustGetsVoucher";

export default {
  name: "FPromotionRuleshWhatCustomerGets",
  components: {
    FPromotionRuleshWhatCustGetsDiscItem,
    FPromotionRuleshWhatCustGetsFg1,
    FPromotionRuleshWhatCustGetsDiscNota,
    FPromotionRuleshWhatCustGetsVoucher
  }
}
</script>

<style scoped>

</style>