<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >

      <v-card>
        <v-form  v-model="valid" ref="form">

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-autocomplete
                      v-model="itemValidCust.validCustomerBean"
                      :items="itemsFCustomer"
                      item-value="id"
                      item-text="custname"
                      auto-select-first
                      dense
                      small-chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      :label="itemValidCust.validCustomerBean===0? 'All Customer': 'Customer'"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-autocomplete
                      v-model="itemValidCust.validAreaBean"
                      :items="itemsFArea"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      small-chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      :label="itemValidCust.validAreaBean===0?'All Area': 'Area' "
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-autocomplete
                      v-model="itemValidCust.validRegionBean"
                      :items="itemsFRegion"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      small-chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      :label="itemValidCust.validRegionBean===0?'All Region': 'Region'"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-autocomplete
                      v-model="itemValidCust.validCustomerGroupBean"
                      :items="itemsFCustomerGroup"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      small-chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      :label="itemValidCust.validCustomerGroupBean===0?'All Cust Group': 'Customer Group'"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-autocomplete
                      v-model="itemValidCust.validDistributionChannelBean"
                      :items="itemsFDistributionChannel"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      small-chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      :label="itemValidCust.validDistributionChannelBean===0?'All Dist Channel': 'Dist Channel'"
                  ></v-autocomplete>
                </v-col>
              </v-row>

           </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down mr-5"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </v-dialog>
  </div>

</template>

<script>
import FPromotionRulesdValidCustsService from '../../../services/apiservices/f-promotion-rulesd-valid-custs-service';

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";

export default {
  components: { CloseConfirmDialog },
  props:{
    formMode: String,

  },
  data() {
    return {
      title: 'Valid Customer',
      snackBarMesage: '',
      snackbar: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemsFDivision: [],

      valid: false,

      transparent: 'rgba(255, 255, 255, 0)',

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemValidCust)
      return defaultItem !== modifiedItem
    },
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },
    itemValidCust: {
      get () {
        return this.$store.state.aktifitaspromo.itemValidCust
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemValidCust', value)
      }
    },


    itemsFCustomer(){
      // return this.$store.state.data.listFCustomer
      return []
    },
    itemsFArea(){
      return this.$store.state.data.listFArea.filter(x => x.statusActive===true)
    },
    itemsFRegion(){
      return this.$store.state.data.listFRegion.filter(x => x.statusActive===true)
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup.filter(x => x.statusActive===true)
    },
    itemsFDistributionChannel(){
      return this.$store.state.data.listFDistributionChannel.filter(x => x.statusActive===true)
    }

  },
  watch: {
  },

  methods: {

    showDialog(selectedIndex) {

      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(this.itemValidCust)
      }else {
        this.selectedIndex = -1
      }

    },

    setDialogState(value){
      this.dialogShow =value
    },
    save(){
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }
      if (this.$refs.form.validate()){

        // console.log(`helo bos ${this.itemModified.id}`)

        this.itemValidCust.fpromotionRuleshBean = this.itemModified.id

        if (this.formMode===FormMode.EDIT_FORM) {
          FPromotionRulesdValidCustsService.updateFPromotionRulesdValidCusts(this.itemValidCust).then(
              () => {
                // console.log(response.data)
                this.$emit('eventFromFormDialogEdit', this.itemValidCust)
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {
          FPromotionRulesdValidCustsService.createFPromotionRulesdValidCusts(this.itemValidCust).then(
              response =>{
                this.$emit('eventFromFormDialogNew', response.data)
              },
              error => {
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }
      }
    },
    saveCreateOnly(){
      FPromotionRulesdValidCustsService.createFPromotionRulesdValidCusts(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            // this.initializeEditMode(response.data)
            this.$emit('update:formMode', FormMode.EDIT_FORM)
            this.itemModified.id = response.data.id
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FPromotionRulesdValidCustsService.updateFPromotionRulesdValidCusts(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){

      this.formDialogOptions.errorMessage = ''

      FPromotionRulesdValidCustsService.getFPromotionRulesdValidCustsById(item.id).then(
          response =>{
            // console.log(response.data)

            this.itemDefault = Object.assign({}, response.data)
            this.itemValidCust = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },


  }

}
</script>

<style scoped>

  .card-hover-opacity{
    opacity: 0.4;
  }
  .card-not-hover-opacity{
    opacity: 1;
    transition: opacity .4s ease-in-out;
  }

  .show-btns {
    color: blue !important;
    opacity: 1;
  }

</style>