var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ma-6"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsValidCusts},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ml-4 mr-4",attrs:{"align":"center"}},[_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","color":"green","x-small":""},on:{"click":_vm.showDialogNew}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.validCustomerBean",fn:function(ref){
var item = ref.item;
return [(item.validCustomerBean===0)?_c('div',[_c('span',[_vm._v("All Customer")])]):_c('div',[_vm._v(" "+_vm._s(item.validCustomerBean)+" ")])]}},{key:"item.validAreaBean",fn:function(ref){
var item = ref.item;
return [(item.validAreaBean===0)?_c('div',[_c('span',[_vm._v("All Area")])]):_c('div',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFArea(item.validAreaBean).description)+" ")])]}},{key:"item.validRegionBean",fn:function(ref){
var item = ref.item;
return [(item.validRegionBean===0)?_c('div',[_c('span',[_vm._v("All Region/Propinsi")])]):_c('div',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFRegion(item.validRegionBean).description)+" ")])]}},{key:"item.validCustomerGroupBean",fn:function(ref){
var item = ref.item;
return [(item.validCustomerGroupBean===0)?_c('div',[_c('span',[_vm._v("All Jenis Cust")])]):_c('div',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFCustomerGroup(item.validCustomerGroupBean).description)+" ")])]}},{key:"item.validDistributionChannelBean",fn:function(ref){
var item = ref.item;
return [(item.validDistributionChannelBean===0)?_c('div',[_c('span',[_vm._v("All Channel")])]):_c('div',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFDistributionChannel(item.validDistributionChannelBean).description)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showDialogEdit(item)}}},[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteDialogShow(item)}}},[_c('v-icon',{attrs:{"small":"","color":"red accent-4"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1),_c('FPromotionRulesdValidCustsDialog',{ref:"refFormDialog",attrs:{"formMode":_vm.formMode,"title":_vm.title},on:{"update:formMode":function($event){_vm.formMode=$event},"update:form-mode":function($event){_vm.formMode=$event},"eventFromFormDialogNew":_vm.saveDataNew,"eventFromFormDialogEdit":_vm.saveDataEdit}}),_c('DeleteDialog',{ref:"refDeleteConfirmDialog",on:{"eventFromDeleteConfirmDialog1":_vm.deleteItemConfirmedSingleSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }