import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FPromotionRuleshService {
    getApiUrl() {
        return API_URL
    }
    getAllFPromotionRulesh(){
        return axios.get(API_URL + `getAllFPromotionRulesh`, { headers: authHeader() });
    }
    getAllFPromotionRuleshByCompany(){
        return axios.get(API_URL + `getAllFPromotionRuleshByCompany`, { headers: authHeader() });
    }
    getAllFPromotionRuleshContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFPromotionRulesh`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFPromotionRuleshContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFPromotionRuleshById(id){
        return axios.get(API_URL + `getFPromotionRuleshById/${id}`, { headers: authHeader() });
    }
    updateFPromotionRulesh(item){
        return axios.put(API_URL + `updateFPromotionRulesh/${item.id}`, item, {headers: authHeader()})
    }
    createFPromotionRulesh(item){
        return axios.post(API_URL + `createFPromotionRulesh`, item, {headers: authHeader()})
    }
    deleteFPromotionRulesh(id){
        return axios.delete(API_URL + `deleteFPromotionRulesh/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFPromotionRulesh(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFPromotionRulesh`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFPromotionRulesh`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FPromotionRuleshService()