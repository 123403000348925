<template>

  <v-container>

    <v-row >
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-container class="text-center">
          <v-row
              class="fill-height"
              align="center"
              justify="center"
          >
            <template>
              <v-col
                  cols="12"
                  md="6"
                  sm="6"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                      rounded
                      :elevation="hover ? 10 : 1"
                      :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
                      class="rounded-0"
                  >
                    <v-img
                        :lazy-src="lookupImageUrlLazy(itemModified)"
                        :src="lookupImageUrl(itemModified)"
                        class="rounded-0"
                    >
                      <v-card-title class="text-h6 white--text fill-height">

                        <v-row
                            class="fill-height flex-column"
                            justify="space-between"
                        >
                          <v-spacer/>
                          <div class="align-self-center">
                            <v-btn
                                :class="{ 'show-btns': hover }"
                                :color="transparent"
                                icon
                                large
                                dark
                                outlined
                                @click="showDialogUpload"
                            >
                              <v-icon
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  large
                              >
                                mdi-upload
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>

                      </v-card-title>
                    </v-img>

                  </v-card>
                </v-hover>
              </v-col>
            </template>
          </v-row>
        </v-container>

      </v-col>

      <v-col
          cols="12"
          sm="8"
          md="8"
      >

        <v-row>
          <v-col
              cols="12"
              sm="6"
              md="4"
          >
            <v-text-field
                v-model="itemModified.kode1"
                :rules="rulesNotEmtpy"
                label="Kode"
                dense
                hide-details
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="2"
              md="2"
          >
            <v-switch
                v-model="itemModified.statusActive"
                :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
                x-small
                dense
                hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-text-field
                v-model="itemModified.description"
                label="Nama PromotionRulesh"
                :rules="rulesNotEmtpy"
                dense
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-flex class="d-flex justify-start">
          <span class="mr-2 subtitle-1 font-weight-bold">
          Masa Berlaku
          </span>
              <DateRangePicker
                  v-model="datePickerRange"
                  :locale-data="dprLocale"
                  @update="changeDatePickerRange"
                  :autoApply="dprAutoApply"
                  :showDropdowns="dprShowDropdowns"
              ></DateRangePicker>
            </v-flex>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          sm="5"
          md="5"
      >
        <v-autocomplete
            v-model="itemModified.fdivisionBean"
            :items="itemsFDivision"
            :rules="rulesNotEmtpy"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Division/Branch"
            :hint="`Division = Branch/Distributor : ${lookupFDivision(itemModified.fdivisionBean)}`"
            persistent-hint
        ></v-autocomplete>
      </v-col>

      <v-col
          cols="12"
          sm="4"
          md="4"
          class="align-baseline"
      >
        <v-switch
            v-model="itemModified.sharedToCompany"
            :label="itemModified.sharedToCompany?'Berlaku Satu Company':'Untuk Divisi Saja'"
            x-small
            dense
            hide-details
        ></v-switch>
      </v-col>

    </v-row>

    <v-row class="grey lighten-4">
      <v-col
          cols="12"
          sm="5"
          md="5"
      >
        <v-select
            v-model="itemModified.calcMethod"
            :items="itemsCalcMethod"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
        ></v-select>
      </v-col>

      <v-col
          cols="12"
          sm="2"
          md="2"
          class="align-baseline"
      >
        <v-switch
            v-model="itemModified.claimPabrik"
            :label="itemModified.claimPabrik?'Klaim Pabrik':'Tidak Klaim'"
            x-small
            dense
        ></v-switch>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-text-field
            v-model="itemModified.maxTargetValue"
            label="Max Target Rp"
            dense
            type="number"
            hint="Total Rp Applied: "
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-text-field
            v-model="itemModified.maxTargetQty"
            label="Max Target Qty"
            dense
            type="number"
            hint="Total Qty Applied: "
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-autocomplete
            v-model="itemModified.accAccountEntityBean"
            :items="itemsAccAccount"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Credit"
            persistent-hint
        ></v-autocomplete>
      </v-col>

      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-autocomplete
            v-model="itemModified.accAccount_CreditBean"
            :items="itemsAccAccount"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Credit"
            persistent-hint
        ></v-autocomplete>
      </v-col>
    </v-row>

    <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
    >
    </UploadImageDialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

</template>

<script>
import {format, parseISO} from "date-fns";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";
import FPromotionRuleshService from "../../../services/apiservices/f-warehouse-service"
import FormMode from "../../../models/form-mode";

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'


export default {
  components:{
    UploadImageDialog,
    DateRangePicker
  },
  props:{
    itemsFDivision: [],
    itemsAccAccount:[]

  },
  data() {
    return{
      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      dates: ['2021-09-10', '2019-09-20'],

      selectedItemIndex: -1,
      itemDefault: '',
      selectFDivision: {id: 0, kode1: '', description: '' },
      itemsCalcMethod: [
        {id: 0, description: '0. Dikalkulasi Setelah Save'},
        {id: 1, description: '1. Dikalkulasi Saat Mode Edit dan Setelah Save'},
        {id: 2, description: '2. Dikalkulasi Saat Mode Edit Saja'},
      ],

      datePickerRange: {
        startDate: function(d){ d.setDate(d.getDate()-0); return d}(new Date),
        endDate: new Date(),
      },
      dprLocale: {
        direction: 'ltr', //direction of text
        format: 'dd/mm/yyyy',
        separator: ' - ', //separator between the two ranges
        applyLabel: 'Terapkan',
        cancelLabel: 'Batal',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        // daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        // monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      dprAutoApply:true,
      dprShowDropdowns:true,

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  computed:{
    itemModified: {
      get () {
        return this.$store.state.aktifitaspromo.itemModified
      },
      set (value) {
        this.$store.dispatch('aktifitaspromo/updateItemModified', value)
      }
    },

  },
  methods:{
    changeDatePickerRange(){
      console.log(`${this.datePickerRange.startDate} >> ${this.datePickerRange.endDate}`)
    },

    showDialogUpload(){
      if (this.itemModified.kode1 !==undefined &&
          this.itemModified.description !==undefined &&
        this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly()
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Pcode, Pname dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },
    completeUploadSuccess: function (val){

      if (this.itemModified.avatarImage !==undefined && this.itemModified.avatarImage !== '' ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
            response => {
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )
      }

      if (val.fileName !==""){
        this.$refs.refUploadDialog.closeDialog()
        this.itemModified.avatarImage = val.fileName
        this.saveUpdateOnly()
      }

    },
    saveCreateOnly(){
      FPromotionRuleshService.createFPromotionRulesh(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            this.initializeEditMode(response.data)
            this.formMode = FormMode.EDIT_FORM
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FPromotionRuleshService.updateFPromotionRulesh(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  }

}

</script>


<style scoped>
.card-hover-opacity{
  opacity: 0.4;
}
.card-not-hover-opacity{
  opacity: 1;
  transition: opacity .4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>
