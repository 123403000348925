export default class FCustomer {
  constructor(
    id,
    custno="",
    custname="",
    fdivisionBean,
    statusActive =true,

    branch =false,
    childOf,

    address1="",
    address2="",
    district1="",
    city1="",
    state1="",
    phone,
    countryCode=62,

    zipCode,
    email="",

    whatsApp,
    currency,

    mappingInCode1,
    mappingInCode2,

    mappingOutCode1,
    mappingOutCode2,

    oldKode1="",
    flagNewItem,
    outletActive,


    pkp,
    namaPrshFakturPajak="",
    alamatPrshFakturPajak="",
    namaPengusahaKenaPajak="",
    nikPajak="",
    npwp="",
    tanggalPengukuhanPkp,

    tunaikredit,
    lamaCredit,
    creditlimit,
    maxInvoice,
    namaPemilik,

    latitude,
    longitude,

    basicDisc1Barang,
    basicDisc1PlusBarang,
    disc1RegManual,
    discPlusRegManual,
    priceAltSwalayan,
    fcustomerGroupBean,
    fsubAreaBean,
    fdistributionChannelBean,
    ftPriceAlthBean,
    noPromotionRules,
    exclusiveSalesman,
    notes,

    custOfSalesman,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.custno = custno;
    this.outletActive = outletActive;
    this.oldKode1 = oldKode1;
    this.flagNewItem = flagNewItem;
    this.fdivisionBean = fdivisionBean;

    this.branch =branch;
    this.childOf = childOf;

    this.mappingInCode1 = mappingInCode1;
    this.mappingInCode2 = mappingInCode2;

    this.mappingOutCode1 = mappingOutCode1;
    this.mappingOutCode2 = mappingOutCode2;

    this.custname = custname;
    this.currency = currency;

    this.pkp = pkp;
    this.namaPrshFakturPajak = namaPrshFakturPajak;
    this.alamatPrshFakturPajak = alamatPrshFakturPajak;
    this.namaPengusahaKenaPajak = namaPengusahaKenaPajak;
    this.nikPajak = nikPajak;
    this.npwp = npwp;

    this.tunaikredit = tunaikredit;
    this.lamaCredit = lamaCredit;
    this.creditlimit = creditlimit;
    this.maxInvoice = maxInvoice;
    this.namaPemilik = namaPemilik;
    this.address1 = address1;
    this.address2 = address2;
    this.district1 = district1;
    this.city1 = city1;
    this.state1 = state1;

    this.countryCode=countryCode;
    this.phone = phone;

    this.zipCode =zipCode;
    this.email  =email;

    this.whatsApp = whatsApp;
    this.statusActive = statusActive;

    this.latitude = latitude;
    this.longitude = longitude;

    this.basicDisc1Barang = basicDisc1Barang;
    this.basicDisc1PlusBarang = basicDisc1PlusBarang;
    this.disc1RegManual = disc1RegManual;
    this.discPlusRegManual = discPlusRegManual;
    this.priceAltSwalayan = priceAltSwalayan;

    this.fcustomerGroupBean = fcustomerGroupBean;
    this.fsubAreaBean = fsubAreaBean;
    this.fdistributionChannelBean = fdistributionChannelBean;
    this.ftPriceAlthBean = ftPriceAlthBean;
    this.noPromotionRules = noPromotionRules;
    this.exclusiveSalesman = exclusiveSalesman;
    this.notes = notes;

    this.custOfSalesman = custOfSalesman;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
